import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../modules/shared/shared.module";
import { MatMenuModule } from "@angular/material/menu";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { PidMappingComponent } from "./pid-mapping/pid-mapping.component";
import { PidMappingFormComponent } from "./pid-mapping-form/pid-mapping-form.component";
import { PidMappingListComponent } from "./pid-mapping-list/pid-mapping-list.component";

// Routes
import { PidMappingsRouting } from "./pid-mappings.routing";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        MatMenuModule,
        NgbModule,
        FontAwesome,
        SharedModule,
        PidMappingsRouting
    ],
    declarations: [PidMappingComponent, PidMappingFormComponent, PidMappingListComponent]
})
export class PidMappingsModule {}
