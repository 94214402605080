import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, firstValueFrom } from "rxjs";

import { Constants } from "../../../constants/constants";
import { ModalService } from "../../../components/shared/modals/modal.service";

import { PidMappingsService } from "../pid-mappings.service";
import { PIDMappingProfile, Rules } from "../pid-mapping";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";

interface PCRRule {
    program_number: number;
}

@Component({
    selector: "app-pid-mapping",
    templateUrl: "./pid-mapping.component.html"
})
export class PidMappingComponent implements OnInit, OnDestroy {
    pidMappingProfile: PIDMappingProfile;
    pidMappingProfileName: string;
    program: string;

    private pidMappingProfilesSubscription: Subscription;

    constants = Constants;
    categories = Constants.pidMapping.categories;
    types = Constants.pidMapping.types;

    pmtRules: Rules[];
    esRules: Rules[];
    pcrRules: PCRRule[] = [];

    loadingDetails = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private pms: PidMappingsService,
        private modalService: ModalService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService
    ) {
        this.route.paramMap.subscribe(params => {
            this.pidMappingProfileName = params.get("name");
            if (this.pidMappingProfileName)
                this.pidMappingProfile = this.pms.getCachedPIDMappingProfile(this.pidMappingProfileName);
            if (!this.pidMappingProfile) return this.cancel();

            // Set Title
            this.titleService.setTitle(
                this.translate.instant("PID_MAPPING_PROFILE") + " - " + this.pidMappingProfile.name
            );

            // Loaded Details?
            if (!this.pidMappingProfile.hasFullDetails) this.loadingDetails = true;
            this.pms.refreshPIDMappingProfile(this.pidMappingProfileName, true);
        });
    }

    ngOnInit() {
        this.pidMappingProfilesSubscription = this.pms.pidMappingProfiles.subscribe(pidMappingProfiles => {
            this.pidMappingProfile = pidMappingProfiles.find(
                (p: PIDMappingProfile) => p.name === this.pidMappingProfileName
            );
            if (this.pidMappingProfile && this.pidMappingProfile.hasFullDetails) this.loadingDetails = false;
            if (this.pidMappingProfile && this.pidMappingProfile.rules) {
                if (this.pidMappingProfile.rules.some(rule => rule.program_number != null)) this.program = "mpts";
                else this.program = "spts";

                this.pcrRules = [];
                if (this.pidMappingProfile.pcr_on_video && typeof this.pidMappingProfile.pcr_on_video === "string") {
                    if (this.pidMappingProfile.pcr_on_video !== "-1") {
                        const s = this.pidMappingProfile.pcr_on_video;
                        const array = s.split(",");
                        for (const pcr of array) {
                            this.pcrRules.push({ program_number: parseInt(pcr, 10) });
                        }
                    }
                }

                this.pmtRules = this.pidMappingProfile.rules.filter(r => r.source === "pmt");
                this.esRules = this.pidMappingProfile.rules.filter(r => r.source !== "pmt");
            }
        });
    }

    ngOnDestroy() {
        if (this.pidMappingProfilesSubscription) this.pidMappingProfilesSubscription.unsubscribe();
    }

    cancel() {
        this.gotoPIDMappingProfiles();
    }

    getCategoryName(source: string) {
        const category = this.categories.find(r => r.value === source);
        return category ? category.name : "";
    }

    getTypeName(source: string) {
        const type = this.types.find(r => r.value === source);
        return type ? type.name : "";
    }

    gotoPIDMappingProfiles() {
        this.router.navigate([Constants.urls.transformation.pid_mapping_profiles]);
    }

    async deletePIDMappingProfile() {
        await this.modalService.confirm(
            "DELETE",
            "PID_MAPPING_PROFILE",
            async () => {
                const id = this.pidMappingProfile.id;
                const result = await this.pms.deletePIDMappingProfile(this.pidMappingProfile);
                if (result) {
                    this.mixpanelService.sendEvent("delete pid mapping profile", { id });
                    this.gotoPIDMappingProfiles();
                } else {
                    return false;
                }
            },
            this.pidMappingProfile.name
        );
    }

    editPIDMappingProfile(name: string): void {
        this.router.navigate([Constants.urls.transformation.pid_mapping_profiles, name, "edit"]);
    }

    clonePIDMappingProfile(name: string): void {
        this.router.navigate([Constants.urls.transformation.pid_mapping_profiles, name, "clone"]);
    }

    refreshPidMappingPromise() {
        return firstValueFrom(this.pms.refreshPIDMappingProfile(this.pidMappingProfileName, true));
    }
}
