import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { PidMappingsService } from "../pid-mappings.service";
import { PIDMappingProfile } from "../pid-mapping";

@Injectable({
    providedIn: "root"
})
export class PidMappingListResolverService implements Resolve<PIDMappingProfile[]> {
    constructor(private pms: PidMappingsService) {}

    resolve(): Observable<PIDMappingProfile[]> | Observable<never> {
        return new Observable((observe: Subscriber<PIDMappingProfile[]>) => {
            this.pms.refreshPIDMappingProfiles().subscribe((pidMappingProfiles: PIDMappingProfile[]) => {
                observe.next(pidMappingProfiles);
                observe.complete();
            });
        });
    }
}
