import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivationEnd } from "@angular/router";
import { TitleCasePipe } from "@angular/common";
import { BehaviorSubject, Subscription, interval } from "rxjs";
import { filter } from "rxjs/operators";

import { Constants } from "../../../constants/constants";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { SharedService } from "../../../services/shared.service";

import { PidMappingsService } from "../pid-mappings.service";
import { PIDMappingProfile } from "../pid-mapping";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";

@Component({
    selector: "app-pid-mapping-list",
    templateUrl: "./pid-mapping-list.component.html",
    providers: [TitleCasePipe]
})
export class PidMappingListComponent implements OnInit, OnDestroy {
    refreshing = false;
    pidMappingProfileName: string;
    selectedRows: PIDMappingProfile[] = [];

    urls = Constants.urls;

    pidMappingProfilesSubject = new BehaviorSubject<PIDMappingProfile[]>([]);

    private routeSubscription: Subscription;
    private pidMappingProfilesSubscription: Subscription;
    private pidMappingProfilesRefreshSubscription: Subscription;

    tableColumnsSchema: TableSchema<PIDMappingProfile>[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 280,
            visible: true,
            sticky: 1,

            sortBy: row => row.name,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => row.name,
                row => row.name,
                () => true
            ),
            textValue: row => row.name
        },
        {
            header: this.translate.instant("TAG"),
            columnDef: "access_tag",
            width: 200,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => row.resourceTags.map(tag => tag.name).join(", "),
                row => row.resourceTags.map(tag => tag.name).join(", "),
                row => !!row.resourceTags.length
            ),
            sortBy: row => row.resourceTags.map(tag => tag.name).join(", "),
            textValue: row => row.resourceTags.map(tag => tag.name).join(", ")
        },
        {
            header: this.translate.instant("DEFAULT_ACTION"),
            columnDef: "default_action",
            width: 280,
            visible: true,

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => this.getDefaultActionTextByRow(row),
                row => this.getDefaultActionTextByRow(row),
                () => true
            ),
            sortBy: row => this.getDefaultActionTextByRow(row),
            textValue: row => this.getDefaultActionTextByRow(row)
        },
        {
            header: this.translate.instant("TYPE"),
            columnDef: "type",
            width: 280,
            visible: true,

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => this.translate.instant(row.type.toUpperCase()),
                row => this.translate.instant(row.type.toUpperCase()),
                () => true
            ),
            sortBy: (row: PIDMappingProfile) => this.translate.instant(row.type.toUpperCase()),
            textValue: (row: PIDMappingProfile) => this.translate.instant(row.type.toUpperCase())
        },
        {
            header: this.translate.instant("RULES"),
            columnDef: "rules",
            width: 280,
            visible: true,

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => row._frontData.ruleCount.toString(),
                row => row._frontData.ruleCount.toString(),
                () => true
            ),
            sortBy: (row: PIDMappingProfile) => row._frontData.ruleCount.toString(),
            textValue: (row: PIDMappingProfile) => row._frontData.ruleCount.toString()
        }
    ];

    constructor(
        private router: Router,
        private pms: PidMappingsService,
        public sharedService: SharedService,
        private modalService: ModalService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService,
        private titleCasePipe: TitleCasePipe
    ) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0))
            .subscribe((event: ActivationEnd) => {
                if (event.snapshot.params && event.snapshot.params.name) {
                    this.pidMappingProfileName = event.snapshot.params.name;
                } else {
                    this.pidMappingProfileName = null;
                }

                this.updateSelectedProfile();
            });
    }

    private updateSelectedProfile() {
        const profile = this.pidMappingProfilesSubject
            .getValue()
            .find(profile => profile.name === this.pidMappingProfileName);
        this.selectedRows = profile ? [profile] : [];
    }

    ngOnInit() {
        this.pidMappingProfilesSubscription = this.pms.pidMappingProfiles.subscribe(pidMappingProfiles => {
            if (pidMappingProfiles) {
                this.pidMappingProfilesSubject.next([...pidMappingProfiles]);
                this.updateSelectedProfile();
            }
        });

        setTimeout(() => {
            this.updateSelectedProfile();
        }, 1000);

        // Start Auto Refresh
        this.startPIDMappingProfilesRefresh();
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.pidMappingProfilesSubscription.unsubscribe();
        this.stopPIDMappingProfilesRefresh();
    }

    getDefaultActionTextByRow(row: PIDMappingProfile): string {
        return row.default_action !== "set_null"
            ? this.titleCasePipe.transform(row.default_action)
            : this.translate.instant("SET_NULL");
    }

    selectRow(pidMappingProfile: PIDMappingProfile) {
        this.router.navigate([Constants.urls.transformation.pid_mapping_profiles, pidMappingProfile.name]);
    }

    async refresh() {
        this.refreshing = true;
        let pidMappingProfile = null;
        const pidMappingProfiles = this.pms.refreshPIDMappingProfiles(true).toPromise();
        if (this.pidMappingProfileName) {
            pidMappingProfile = this.pms.refreshPIDMappingProfile(this.pidMappingProfileName, true).toPromise();
        }
        await Promise.all([pidMappingProfiles, pidMappingProfile]);
        this.refreshing = false;
    }

    async multiAction(action: string, func: (pidMappingProfile: PIDMappingProfile) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "PID_MAPPING_PROFILE", this.selectedRows, func);
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(
                this.translate.instant(action).toLowerCase() + " multiple pid mapping profiles"
            );
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    multiDelete() {
        this.multiAction("DELETE", async (pidMappingProfile: PIDMappingProfile) =>
            this.pms.deletePIDMappingProfile(pidMappingProfile)
        );
    }

    async multiEdit() {
        await this.modalService.editMultiple(
            "PID_MAPPING_PROFILE",
            this.selectedRows,
            async (pid: PIDMappingProfile, model) => {
                return this.pms.updatePIDMappingProfile(pid, model);
            }
        );
    }

    startPIDMappingProfilesRefresh() {
        this.pidMappingProfilesRefreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    stopPIDMappingProfilesRefresh() {
        this.pidMappingProfilesRefreshSubscription.unsubscribe();
    }
}
